export default class CredentialEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#credential_id") ? (elem.querySelector("#credential_id") as HTMLInputElement).value : null,
            msTenantId: (elem.querySelector("#credential_msTenantId") as HTMLInputElement).value,
            msClientId: (elem.querySelector("#credential_msClientId") as HTMLInputElement).value,
            msClientSecret: (elem.querySelector("#credential_msClientSecret") as HTMLInputElement).value,
            msEmailAccount: (elem.querySelector("#credential_msEmailAccount") as HTMLInputElement).value,
            msUserId: (elem.querySelector("#credential_msUserId") as HTMLInputElement).value,
            msUserGroup: (elem.querySelector("#credential_msUserGroup") as HTMLInputElement).value,
            shopwareUrl: (elem.querySelector("#credential_shopwareUrl") as HTMLInputElement).value,
            shopwareStorefrontId: (elem.querySelector("#credential_shopwareStorefrontId") as HTMLInputElement).value,
            shopwareIntegrationClientId: (elem.querySelector("#credential_shopwareIntegrationClientId") as HTMLInputElement).value,
            shopwareIntegrationClientSecret: (elem.querySelector("#credential_shopwareIntegrationClientSecret") as HTMLInputElement).value,
            dhlUsername: (elem.querySelector("#credential_dhlUsername") as HTMLInputElement).value,
            dhlPassword: (elem.querySelector("#credential_dhlPassword") as HTMLInputElement).value
        }
    }
}